import React from "react"
import styles from './home.module.scss'


export default () => 
    <div className={styles.welcome}>
        <p> Hi there! <br />
        This site is still under construction. <br />
        Please check back later.</p>
    </div>
